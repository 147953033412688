<template>
  <div>
    <div v-if="!$store.state.license.valid.value" class="container--error">
      {{ $tf('error.license.not_valid') }}
      <br />
      <i v-if="!($store.state.license.props && $store.state.license.props.end <= $store.state.license.time)">{{ $store.state.license.valid.description }}</i>
    </div>
    <div v-if="!$store.state.license.source" class="container--error">
      {{ $tf('error.license.file_not_found') }}
    </div>
    <div v-if="$store.state.license.props && $store.state.license.props.end <= $store.state.license.time" class="container--error">
      {{ $tf('error.license.expired') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-errors'
};
</script>
