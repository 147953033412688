<template>
  <main class="license">
    <h2 v-if="component === Components.ntls">{{ $tf('information') }}</h2>

    <content-errors v-if="$store.state.license"></content-errors>

    <el-tabs v-model="section" v-if="$store.state.license">
      <el-tab-pane :label="$tf('common')" name="common">
        <div>
          <span>{{ $tf('valid') }}</span>
          <span>
            <el-button size="small" :type="$store.state.license.valid.value ? 'success' : 'danger'">
              {{ $tf($store.state.license.valid.value ? 'yes' : 'no') }}
            </el-button>
          </span>
        </div>
        <div>
          <span>{{ $tf('type | of | license,,1') }}</span>
          <span>{{ $store.state.license.type }}</span>
        </div>
        <div>
          <span>{{ $tf('license_id') }}</span>
          <span>{{ $store.state.license.license_id }}</span>
        </div>
        <div>
          <span>{{ $tf('file') }}</span>
          <span>{{ $store.state.license.source }}</span>
          <div class="license-controls">
            <el-button type="info" @click="$refs.file.click()" class="license-controls__btn">
              {{ $tf({ ru: 'upload_v | new | file | license,,1', en: 'upload | new | license | file' }) }}
            </el-button>
            <el-button v-if="$store.state.license && !$store.state.license.valid.value" class="license-controls__btn" type="info" @click="downloadC2V">
              {{ $tf('license_download_c2v') }}
            </el-button>
          </div>
        </div>
        <div v-if="$store.state.license.generated" class="clear">
          <span>{{ $tf('generated') }}</span>
          <span>{{ $store.state.license.generated | dateTime }}</span>
        </div>
        <div v-if="$store.state.license && $store.state.license.last_updated !== null">
          <span>{{ $tf('last_updated') }}</span>
          <span>{{ lastUpdated($store.state.license.last_updated) }}</span>
        </div>
      </el-tab-pane>

      <el-tab-pane :label="$tf('limits')" name="limits" v-if="$store.state.license.limits">
        <div v-for="item in $store.state.license.limits">
          <span>{{ item.name | startCase }}</span>
          <span v-if="item.type === 'time'">{{ item.value | dateTime }}</span>
          <span v-else-if="item.type === 'number'">{{ item.current || 0 }}/{{ item.value }}</span>
          <span v-else-if="item.type === 'boolean'">{{ $tf(item.value ? 'enabled' : 'disabled') }}</span>
          <span v-else>{{ item.value }}</span>
        </div>
      </el-tab-pane>

      <el-tab-pane :label="$tf('intervals')" name="intervals" v-if="hasIntervals">
        <div class="cols">
          <div class="flex-cell--static">
            <interval-item
              :item="item"
              :selected="key === selectedIntervalIndex"
              :index="key"
              @select="selectIntervalHandler"
              v-for="(item, key) in intervals"
              :key="key"
            />
          </div>
          <div class="flex-cell interval-data" v-if="counters">
            <div v-for="(item, key) in counters">
              <span>{{ key | startCase }}</span>
              <span :style="{ color: getUsageColor(item) }">{{ item.used || 0 }} / {{ item.available || '-' }}</span>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane :label="$tf('services')" name="services">
        <div v-for="item in $store.state.license.services">
          <span>{{ item.name | startCase }}</span>
          <span>{{ item.ip }}</span>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div v-else>
      <h3>Has no license information.</h3>
      {{ $tf('remote | url') }}: {{ $store.state.remote.url }}<br />
      {{ $tf('attempts') }}: {{ $store.state.remote.attempts }}
    </div>

    <br />
    <p>{{ $tf('updated_at,,2') }} {{ $store.state.remote.updated | time }}</p>

    <h2>
      <a href="https://findface.pro" target="_blank">
        <el-button type="primary">
          {{ $tf('buy | or | update | license,,2') }}
        </el-button>
      </a>
    </h2>

    <input type="file" style="display: none" ref="file" />
  </main>
</template>

<script>
import _ from '@/apps/common/lodash';
import dateUtil from 'element-ui/src/utils/date';
import ContentErrors from './errors';
import IntervalItem from './interval.item';

const Components = { ntls: 'ntls' };
const UpdateInterval = 5000;
const MaxLicenseFileSize = 1024 * 1024;
let updateIntervalInstance = 0;

export default {
  components: { IntervalItem, ContentErrors },
  name: 'main-license',
  data: function () {
    return {
      section: 'common',
      selectedIntervalIndex: 0,
      Components
    };
  },
  computed: {
    counters() {
      return this.interval ? this.interval.counters : null;
    },
    intervals() {
      const report = this.$store.state.licenseReport || {};
      return [report.active_limits, ...(report.by_interval || [])].filter((v) => !!v);
    },
    interval() {
      return this.intervals[this.selectedIntervalIndex];
    },
    component() {
      return this.$store.state.config.component;
    },
    time: function () {
      return Math.random();
    },
    hasIntervals() {
      return !!this.intervals.length;
    }
  },
  mounted() {
    clearInterval(updateIntervalInstance);
    updateIntervalInstance = setInterval(() => this.$store.dispatch('getLicenseStatus'), UpdateInterval);
    this.$refs.file.addEventListener('change', this.fileHandler);
    this.load();
  },
  beforeDestroy() {
    clearInterval(updateIntervalInstance);
  },
  methods: {
    downloadC2V() {
      this.$store.dispatch('downloadC2V').catch((e) => {
        this.$notify({ message: e.toString(), type: 'error' });
      });
    },
    getUsageColor(item) {
      const usageToColors = [
          [0.8, ''],
          [0.99, 'yellow'],
          [1, 'red']
        ],
        valid = item.available > 0 && item.used >= 0;
      if (!valid) return '';
      const maxUsage = item.used / item.available;
      const maxUsageItem = usageToColors.find((v) => maxUsage <= v[0]);
      return maxUsageItem ? maxUsageItem[1] : 'gray';
    },
    selectIntervalHandler(v) {
      this.selectedIntervalIndex = v;
    },
    lastUpdated(value) {
      return value + ' ' + this.$tt('common.second_ago_pf', value);
    },
    load() {
      this.$store.dispatch('getLicenseStatus').catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      });

      this.$store.dispatch('getLicenseReportStatus').catch((e) => {
        console.warn('[license-report]:load:error ', e);
      });
    },
    fileHandler(e) {
      e.preventDefault();

      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        this.$refs.file.value = null;

        if (file.size > MaxLicenseFileSize) {
          this.$notify({
            message: this.$tf('error.license.file_too_large'),
            type: 'error'
          });
          return;
        }

        let options = {
            method: 'POST',
            body: file,
            headers: new Headers()
          },
          token = this.$store.state.app && this.$store.state.app.token,
          host = (this.$store.state.config && this.$store.state.config.server && this.$store.state.config.server.url) || '',
          url = host + (this.$store.state.remote.importUrl || '/import');

        if (token) {
          options.headers.append('Authorization', 'Token ' + encodeURIComponent(token));
        } else {
          options.credentials = 'include';
        }

        window
          .fetch(url, options)
          .then((v) => {
            return v.json();
          })
          .then((v) => {
            if (v.success) {
              this.$notify({
                message: this.$tf('license_file_uploaded'),
                type: 'success'
              });
              this.load();
            } else {
              return Promise.reject(v && v.reason);
            }
          })
          .catch((e) => {
            this.$notify({
              message: this.$tf('error') + ': ' + (e || '').toString(),
              type: 'error'
            });
          });
      }
    }
  },
  filters: {
    dateTime(value) {
      return dateUtil.format(value * 1000, 'dd.MM.yyyy HH:mm:ss ZZ');
    },
    time(value) {
      return value ? dateUtil.format(value, 'HH:mm:ss') : '...';
    },
    startCase(value) {
      return _.startCase(value);
    }
  }
};
</script>

<style lang="stylus">
.is-valid {
  color: green;
}

.license {
  .el-tab-pane div {
    line-height: 2rem;
  }
}
.license-controls{
  display: flex;
  justify-content:flex-end;
}
.license-controls__btn{
  margin: 0.5rem 0 0.5rem 1rem;
}

.license div > span:first-child {
  width: 10rem;
  display: inline-block;
}

.license div > span:last-child {
}

.container--error {
  line-height: 2rem;
  padding-left: 2rem;
  margin-bottom: 1rem;
  border: 1px solid red;
  color: red;
}

.clear {
  clear: both;
}

.interval-data {
  padding-left: 2rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
</style>
