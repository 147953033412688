<template>
  <div>
    <div v-if="index === 0" class="text-center text-tetriary text-s">{{ $tf('current').toLocaleUpperCase() }}</div>
    <div v-if="index === 1" class="text-center text-tetriary text-s">{{ $tf('all').toLocaleUpperCase() }}</div>
    <div @click="$emit('select', index)" class="license-interval-item cols" :class="{ 'license-interval-item--selected': selected }">
      <div class="date-since-item">
        <div>{{ item.since | parseDate | formatDate }}</div>
        <div class="text-s">{{ item.since | parseDate | formatTime }}</div>
      </div>
      <div>
        <span class="round-item" :style="{ backgroundColor: statusColor }"></span>
      </div>
      <div class="date-till-item">
        <div>{{ item.till | parseDate | formatDate }}</div>
        <div class="text-s">{{ item.till | parseDate | formatTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as filters from '@/apps/common/filters';

export default {
  name: 'interval-item',
  props: {
    item: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    }
  },
  filters: {
    ...filters
  },
  computed: {
    statusColor() {
      const usageToColors = [
        [0.8, 'green'],
        [0.99, 'yellow'],
        [1, 'red']
      ];
      let maxUsage = this.index === 0 ? 0 : 2;
      if (this.index === 0) {
        maxUsage = Object.entries(this.item.counters || {}).reduce((m, [name, v], k) => {
          const u = v.used / v.available;
          return m > u ? m : u;
        }, maxUsage);
      }

      const maxUsageItem = usageToColors.find((v) => maxUsage <= v[0]);
      return maxUsageItem ? maxUsageItem[1] : 'gray';
    }
  },
  methods: {}
};
</script>

<style lang="stylus">

.license-interval-item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  align-items: center;
  user-select: none;
  cursor: pointer;
  line-height: 1.4rem !important;

  &--selected {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .date-since-item {
    box-sizing: border-box;
    flex: 0 0 100px;
    padding: 0.5rem;
    text-align: right;

    div {
      line-height: 1.4rem;
    }
  }

  .date-till-item {
    flex: 0 0 100px;
    padding: 0.5rem;
    text-align: left;

    div {
      line-height: 1.4rem;
    }
  }

  .clear-width {
    width: auto !important;
  }

  .round-item {
    flex: 0 0;
    display: inline-block;
    width: 0.5rem !important;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #ccc;
  }
}
</style>
