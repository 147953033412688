<template>
  <div>
    <div class="content-header">
      <h2>{{ $tf('license') }}</h2>
    </div>
    <license-content></license-content>
  </div>
</template>

<script>
import licenseContent from '@/components/content/license';

export default {
  name: 'license',
  components: { licenseContent },
  data: function () {
    return {};
  },
  methods: {}
};
</script>
